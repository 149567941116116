import React, { useState } from "react";
import { Col, Container, Row } from "reactstrap";
import { useAuth0 } from "@auth0/auth0-react";
import { Link, useNavigate } from "react-router-dom";

import Loader from "../../Components/Common/Loader";
import EditAccountModal from "../../modals/EditAccountModal";
import DeleteListItemButton from "../../Components/Forms/DeleteListItemButton";
import CreateOrUpdateModal from "../../modals/CreateOrUpdateModal";
import CreateAccountForm from "../../Components/Forms/CreateAccountForm";
import SubmitFormButton from "../../Components/Forms/SubmitFormButton";
import useAccounts from "../../hooks/useAccounts";
import BreadCrumb from "../../Components/Common/BreadCrumb";

const domain = process.env.REACT_APP_AUTH0_DOMAIN
const apiDomain = process.env.REACT_APP_F2G_API_DOMAIN

const Accounts = () => {
  const { getAccessTokenSilently } = useAuth0()
  const { data: accounts, loading } = useAccounts()
  const navigate = useNavigate()

  // Create new account modal
  const [saveAccountLoading, setSaveAccountLoading] = useState(false)
  const [saveAccountErrors, setSaveAccountErrors] = useState([])
  const [saveAccountFormOpen, setSaveAccountFormOpen] = useState(false)

  // Edit account modal
  const [editAccount, setEditAccount] = useState(undefined)

  const onSaveAccount = async (e, account) => {
    e.preventDefault()
    setSaveAccountLoading(true)

    try {
      const accessToken = await getAccessTokenSilently({
        audience: `https://${domain}/api/v2/`,
        scope: "read:current_user",
      });

      const accountResponse = await fetch(`${apiDomain}/accounts`, {
        method: 'POST',
        headers: {
          "Authorization": `Bearer ${accessToken}`,
          "Content-Type": 'application/json',
        },
        body: JSON.stringify(account)
      });

      const { data, errors } = await accountResponse.json()
      setSaveAccountLoading(false)

      if (errors && errors.length > 0) {
        setSaveAccountErrors(errors)
        return
      }

      setSaveAccountErrors([])
      if (account.id) {
        window.location.reload()
      } else {
        navigate(`/dashboard/accounts/${data.id}`)
      }
    } catch (e) {
      console.log(e)
    }
  }

  document.title = "Accounts"
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb parentTitle="Dashboard" parentLink="/dashboard" title="Accounts" childLabel="Accounts" />
          <Row>
            <Col>
              <div className="h-100">
                <Row>
                  {loading && (
                    <Loader />
                  )}

                  {!loading && (
                    <div>
                      <div className="row">
                        <div className="col-sm-12 col-md-12 col-xl-12">
                          <div className="card">
                            <div className="card-body">
                              <button onClick={() => { setEditAccount(undefined); setSaveAccountFormOpen(true) }} type="button" className="btn btn-secondary waves-effect waves-light">
                                <i className="ri-add-fill"></i>{' '}
                                Add Account
                              </button>
                              <CreateOrUpdateModal
                                buttonText="Add new account"
                                form={
                                  <CreateAccountForm
                                    account={editAccount}
                                    loading={saveAccountLoading}
                                    errors={saveAccountErrors}
                                    onSubmit={onSaveAccount}
                                    formId="createAccountForm"
                                    open={saveAccountFormOpen}
                                    setOpen={setSaveAccountFormOpen}
                                  />
                                }
                                open={saveAccountFormOpen}
                                setOpen={setSaveAccountFormOpen}
                                submitButton={<SubmitFormButton loading={saveAccountLoading} formId="createAccountForm" />}
                                title="Add Account"
                              />
                            </div>
                          </div>
                          <div className="card">
                            {accounts.length > 0 && (
                              <table className="table align-middle table-nowrap mb-0 table-responsive">
                                <thead className="table-secondary">
                                  <tr>
                                    <th scope="col"></th>
                                    <th scope="col" style={{}}>Name</th>
                                    <th scope="col" style={{ width: '20%' }}>Location</th>
                                    <th scope="col" style={{ width: '20%' }}>Total Calls</th>
                                    <th scope="col" style={{ width: '5%' }}></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {accounts.map((account, index) => (
                                    <tr key={index}>
                                      <td style={{ width: '7%' }}>
                                        {account.logo && (
                                          <div className="f2g-logo" style={{
                                            backgroundImage: `url("${apiDomain}/account-logos/${account.logo}")`,
                                            margin: '0 auto',
                                            height: '50px',
                                            width: '50px',
                                          }}>&nbsp;</div>
                                        )}
                                      </td>
                                      <td style={{ width: '15%' }}><Link to={`/dashboard/accounts/${account.id}`}>{account.name}</Link></td>
                                      <td>
                                        {account.city && <span>{account.city}</span>}
                                        {(account.city && account.state) && <span>,</span>}
                                        {account.state && <span>{account.state}</span>}
                                      </td>
                                      <td>
                                        {account.callCount}
                                      </td>
                                      <td style={{ textAlign: 'right' }}>
                                        <div className="btn-group">
                                          <button onClick={() => { setEditAccount(account); setSaveAccountFormOpen(true) }} type="button" className="btn btn-light">
                                            <i className="ri-pencil-line"></i>
                                          </button>
                                          <Link to={`/dashboard/accounts/${account.id}/settings`} className="btn btn-light">
                                            <i className="ri-settings-2-line"></i>
                                          </Link>
                                          <DeleteListItemButton id={account.id} noun="account" endpoint={`${apiDomain}/accounts/${account.id}`}>
                                            <i className="ri-delete-bin-line"></i>
                                          </DeleteListItemButton>
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            )}
                            <div className="card-body">
                              <span className="text-muted" style={{ fontSize: '12px' }}>
                                {accounts.length > 0 ? `${accounts.length} total account${accounts.length > 1 ? 's' : ''}` : 'No accounts created yet'}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Accounts;

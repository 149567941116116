import React, { useState } from 'react'
import { useAuth0 } from "@auth0/auth0-react";

import CreateOrUpdateModal from './CreateOrUpdateModal' 
import CreateAccountForm from '../Components/Forms/CreateAccountForm'
import SubmitFormButton from '../Components/Forms/SubmitFormButton'

const domain = process.env.REACT_APP_AUTH0_DOMAIN
const apiDomain = process.env.REACT_APP_F2G_API_DOMAIN

const EditAccountModal = ({
  account: accountTemplate,
  open,
  setOpen
}) => {
  const { getAccessTokenSilently } = useAuth0()
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState([])
  
  const onSubmit = async (e, account) => {
    e.preventDefault()
    setLoading(true)

    try {
      const accessToken = await getAccessTokenSilently({
        audience: `https://${domain}/api/v2/`,
        scope: "read:current_user",
      });
  
      const accountResponse = await fetch(`${apiDomain}/accounts`, {
        method: 'POST',
        headers: {
          "Authorization": `Bearer ${accessToken}`,
          "Content-Type": 'application/json',
        },
        body: JSON.stringify(account)
      });

      const { data, errors } = await accountResponse.json()
      setLoading(false)

      if (errors && errors.length > 0) {
        setErrors(errors)
        return
      }

      setErrors([])
      setOpen(false)
      window.location.reload()
    } catch (e) {
      console.log(e)
    }
  }

  const formId = `editAccount-${accountTemplate.id}`

  const form = (
    <CreateAccountForm
      account={accountTemplate}
      formId={formId}
      loading={loading}
      errors={errors}
      onSubmit={onSubmit}
    />
  )

  const submitButton = (
    <SubmitFormButton
      formId={formId}
      loading={loading}
    />
  )

  return (
    <CreateOrUpdateModal
      form={form}
      open={open}
      setOpen={setOpen}
      submitButton={submitButton}
      title="Edit Account Details"
    />
  )
}

export default EditAccountModal
import React from "react";
import ReactApexChart from "react-apexcharts";
import { getChartColorsArray } from "../../helpers/chartColorHelper";

const TopDispositionsPieChart = ({ dataColors, data }) => {
  var chartDonutBasicColors = getChartColorsArray(dataColors);
  const labels = data.map(x => x.disposition)
  const series = data.map(x => parseInt(x.dispositionCount))

  var options = {
      chart: {
          height: 500,
          type: 'pie',
      },
      labels,
      legend: {
          position: 'bottom'
      },
      dataLabels: {
          dropShadow: {
              enabled: false,
          }
      },
      colors: chartDonutBasicColors,
      tooltip: {
        shared: true,
        y: [{
          formatter: function (y) {
            if (typeof y !== "undefined") {
              return `${y}%`;
            }
            return y;
          }
        }]
      }
  }

  return (
      <ReactApexChart
        className="apex-charts"
        series={series}
        options={options}
        type="pie"
        height={407.5}
      />
  )
}

export default TopDispositionsPieChart
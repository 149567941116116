import React from "react"

import { RecentActivityScoller } from "./Charts/Account/RecentActivityScroller"

const RecentActivityCard = ({ events }) => {
  return (
    <div className="card">
      <div className="card-header">
        <h4 className="card-title mb-0">F2G Recent Activity</h4>
      </div>
      <div className="card-body" style={{ height: '457px', overflow: 'auto' }}>
        <RecentActivityScoller events={events} />
      </div>
    </div>
  )
}

export default RecentActivityCard
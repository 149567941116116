import { useState, useEffect } from 'react'

const f2gDomain = process.env.REACT_APP_F2G_API_DOMAIN

function useDailyWrapUp(secret) {
  const [loading, setLoading] = useState(true)
  const [accounts, setAccounts] = useState([])
  const [name, setName] = useState(null)
  const [isNotFound, setIsNotFound] = useState(false)

  useEffect(() => {
    const getDailyWrapUpData = async () => {
      setLoading(true)

      try {
        const response = await fetch(`${f2gDomain}/daily-wrap-up/${secret}`)
        if (response.status === 404) {
          setIsNotFound(true)
        }

        if (response.ok) {
          const { accounts, name } = await response.json()

          setAccounts(accounts)
          setName(name)
        }

        setLoading(false)
      } catch (e) {
        console.log(e.message)
      }
    }

    getDailyWrapUpData()
  }, [secret])

  return { accounts, name, loading, isNotFound }
}

export default useDailyWrapUp
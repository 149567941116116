import React, { useEffect, useState, useMemo } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, Label, Card, CardBody, CardTitle } from 'reactstrap'
import moment from 'moment'
import axios from 'axios'

const apiDomain = process.env.REACT_APP_F2G_API_DOMAIN

const topChartTypes = [
  { value: 'top-k-disposition', label: 'Top-5 Dispositions' },
  { value: 'bar', label: 'Bar: SMS/Email/Call/Booked Totals' }
]

const bottomChartTypes = [
  { value: 'half-width-line', label: 'Half-width line graph with description' },
  // { value: 'full-width-line', label: 'Full-width line graph' }
]

function CreateAccountReportModal({ open, setOpen, account }) {
  const toggle = () => setOpen(!open)

  const [loadingStats, setLoadingStats] = useState(false)
  const [startDate, setStartDate] = useState(moment().subtract(7, 'days').format("YYYY-MM-DD"))
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"))
  const [timeframeLabel, setTimeframeLabel] = useState('STATS OVER THE PAST WEEK')
  const [topChartType, setTopChartType] = useState(topChartTypes[0].value)
  const [bottomChartTitle, setBottomChartTitle] = useState('WEEKLY TASK VOLUME')
  const [bottomChartType, setBottomChartType] = useState(bottomChartTypes[0].value)

  const [stats, setStats] = useState(null)

  useEffect(() => {
    const data = {
      accountId: account?.id,
      start: startDate,
      end: endDate,
      timeframeLabel,
      topChartType,
      bottomChartType,
      bottomChartTitle,
    }
    const queryString = Object.keys(data).map((key) => `${key}=${encodeURIComponent(data[key])}`).join('&')

    if (account?.id) {
      setLoadingStats(true)
      axios.get(`${apiDomain}/pdf-report/stats?${queryString}`).then((response) => {
        setStats(response)
      }).finally(() => {
        setLoadingStats(false)
      })
    }
  }, [account.id, endDate, startDate, timeframeLabel, bottomChartTitle, bottomChartType, topChartType])

  const generate = () => {
    const data = {
      accountId: account?.id,
      start: startDate,
      end: endDate,
      timeframeLabel,
      topChartType,
      bottomChartType,
      bottomChartTitle,
    }
    const queryString = Object.keys(data).map((key) => `${key}=${encodeURIComponent(data[key])}`).join('&')
    window.open(`${apiDomain}/pdf-report?${queryString}`, '_blank')
  }

  const disableTimeframeInput = useMemo(() => {
    return topChartType === 'top-k-disposition'
  }, [topChartType])

  return (
    <span>
      <Modal isOpen={open} toggle={toggle} size="lg">
        <ModalHeader toggle={toggle}>Generate a Report</ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-sm-6">
            <Label>Start Date</Label>
              <Input type="date" defaultValue={startDate} onChange={(e) => setStartDate(e.target.value)} />
            </div>
            <div className="col-sm-6">
              <Label>End Date</Label>
              <Input type="date" defaultValue={endDate} onChange={(e) => setEndDate(e.target.value)} />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6">
              <br />
              <Label>Top Chart Type</Label>
              <Input type="select" defaultValue={topChartType} onChange={(e) => setTopChartType(e.target.value)}>
                {topChartTypes.map(type => (
                  <option key={type.value} value={type.value}>{type.label}</option>
                ))}
              </Input>
            </div>
            <div className="col-sm-6">
              <br />
              <Label>Timeframe label</Label>
              <Input type="text" disabled={disableTimeframeInput} value={timeframeLabel} onChange={(e) => setTimeframeLabel(e.target.value)} />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6">
              <br />
              <Label>Bottom Chart Type</Label>
              <Input type="select" defaultValue={bottomChartType} onChange={(e) => setBottomChartType(e.target.value)}>
                {bottomChartTypes.map(type => (
                  <option key={type.value} value={type.value}>{type.label}</option>
                ))}
              </Input>
            </div>
            <div className="col-sm-6">
              <br />
              <Label>Bottom Chart Title</Label>
              <Input type="text" value={bottomChartTitle} onChange={(e) => setBottomChartTitle(e.target.value)} />
            </div>
          </div>

          {stats && (
            <Card color="light" style={{ marginTop: 24 }}>
              {/* <CardHeader>Data Preview</CardHeader> */}
              <CardBody>
                <CardTitle tag="h5">
                  Data Preview
                </CardTitle>
                {loadingStats && (
                  <div>Loading preview...</div>
                )}
                {!loadingStats && (
                  <div>
                    <div className="row">
                      <div className="col-sm-3">
                        <br />
                        <Label>SMS</Label>
                        <Input type="text" disabled={true} value={stats.smsCount} />
                      </div>
                      <div className="col-sm-3">
                        <br />
                        <Label>Email</Label>
                        <Input type="text" disabled={true} value={stats.emailCount} />
                      </div>
                      <div className="col-sm-3">
                        <br />
                        <Label>Calls</Label>
                        <Input type="text" disabled={true} value={stats.callCount} />
                      </div>
                      <div className="col-sm-3">
                        <br />
                        <Label>Booked</Label>
                        <Input type="text" disabled={true} value={stats.callsBooked} />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6">
                        <br />
                        <Label>Call Activity</Label>
                        <Input type="textarea" rows="3" disabled={true} value={stats?.recentActivity?.map(ra => `${ra.date}: ${ra.count}`).join('\n')} />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6">
                        <br />
                        <Label>Top Inbound Dispositions</Label>
                        <Input type="textarea" rows="5" disabled={true} value={stats?.topInboundDispositions?.map(ra => `${ra.disposition}: ${ra.dispositionCount}`).join('\n')} />
                      </div>
                      <div className="col-sm-6">
                        <br />
                        <Label>Top Outbound Dispositions</Label>
                        <Input type="textarea" rows="5" disabled={true} value={stats?.topOutboundDispositions?.map(ra => `${ra.disposition}: ${ra.dispositionCount}`).join('\n')} />
                      </div>
                    </div>
                  </div>
                )}
              </CardBody>
            </Card>
          )}
          
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => generate()}>Generate</Button>
          {' '}
          <Button color="light" onClick={() => setOpen(false)}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </span>
  );
}

export default CreateAccountReportModal
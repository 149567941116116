import React from "react"
import TopDispositionsPieChart from "./Charts/TopDispositionsPieChart"

// import RevenueGeneratedChart from "./Charts/Account/RevenueGeneratedChart"

const TopDispositionsCard = ({
  topInboundDispositions = [],
  topOutboundDispositions = [],
}) => (
  <div className="card">
    <div className="card-header border-0 align-items-center d-flex">
      <h4 className="card-title mb-0 flex-grow-1">Top Disposition Overview (last 30 days)</h4>
    </div>

    <div className="card-header p-0 border-0 bg-soft-light">
      <div className="row g-0 text-center">
        <div className="col-6">
          <div className="p-3 border border-dashed border-start-0">
            <h5 className="mb-1">{topInboundDispositions.reduce((prev, curr) => prev + parseInt(curr.dispositionCount), 0)}</h5>
            <p className="text-muted mb-0">Total Inbound Calls</p>
          </div>
        </div>

        <div className="col-6">
          <div className="p-3 border border-dashed border-start-0">
            <h5 className="mb-1">{topOutboundDispositions.reduce((prev, curr) => prev + parseInt(curr.dispositionCount), 0)}</h5>
            <p className="text-muted mb-0">Total Outbound Calls</p>
          </div>
        </div>
      </div>
    </div>
    <div className="card-body p-0 pb-2">
      <div className="row">
        <div className="col-sm-6">
          <TopDispositionsPieChart data={topInboundDispositions} dataColors='["--vz-primary", "--vz-success", "--vz-warning", "--vz-danger", "--vz-info"]'/>
        </div>
        <div className="col-sm-6">
          <TopDispositionsPieChart data={topOutboundDispositions} dataColors='["--vz-primary", "--vz-success", "--vz-warning", "--vz-danger", "--vz-info"]'/>
        </div>
      </div>
    </div>
  </div>
)

export default TopDispositionsCard
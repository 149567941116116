import { useState, useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'

const auth0Domain = process.env.REACT_APP_AUTH0_DOMAIN
const f2gDomain = process.env.REACT_APP_F2G_API_DOMAIN

function useDailyWrapUpSummary() {
  const { getAccessTokenSilently } = useAuth0()
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])

  useEffect(() => {
    const getDailyWrapUpData = async () => {
      setLoading(true)

      try {
        const accessToken = await getAccessTokenSilently({
          audience: `https://${auth0Domain}/api/v2/`,
          scope: "read:current_user",
        });

        const response = await fetch(`${f2gDomain}/daily-wrap-up/admin`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
        const responseData = await response.json()

        setData(responseData)
        setLoading(false)
      } catch (e) {
        console.log(e.message)
      }
    }

    getDailyWrapUpData()
  }, [getAccessTokenSilently])

  return { data, loading }
}

export default useDailyWrapUpSummary
import { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react'

const auth0Domain = process.env.REACT_APP_AUTH0_DOMAIN
const f2gDomain = process.env.REACT_APP_F2G_API_DOMAIN

function useAccounts() {
  const { user, getAccessTokenSilently } = useAuth0()
  const [loading, setLoading] = useState(true)
  const [accountData, setAccountData] = useState([])
  const [pagingData, setPagingData] = useState({})

  useEffect(() => {
    const getAccountData = async () => {
      setLoading(true)

      try {
        const accessToken = await getAccessTokenSilently({
          audience: `https://${auth0Domain}/api/v2/`,
          scope: "read:current_user",
        });

        const accountsUrl = `${f2gDomain}/accounts`;

        const accountsDataResponse = await fetch(accountsUrl, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        const { data, pageData } = await accountsDataResponse.json()

        setAccountData(data)
        setPagingData(pageData)
        setLoading(false)
      } catch (e) {
        console.log(e.message);
      }
    };

    getAccountData();
  }, [getAccessTokenSilently, user?.sub]);

  return { data: accountData, loading, pagingData };
}

export default useAccounts
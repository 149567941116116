import { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react'

const auth0Domain = process.env.REACT_APP_AUTH0_DOMAIN
const f2gDomain = process.env.REACT_APP_F2G_API_DOMAIN

function useDashboardStats() {
  const { user, getAccessTokenSilently } = useAuth0()
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])

  useEffect(() => {
    const getDashboardStats = async () => {
      setLoading(true)

      try {
        const accessToken = await getAccessTokenSilently({
          audience: `https://${auth0Domain}/api/v2/`,
          scope: "read:current_user",
        });

        const statsUrl = `${f2gDomain}/stats`;

        const statsDataResponse = await fetch(statsUrl, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        const returnedData = await statsDataResponse.json()

        setData(returnedData)
        setLoading(false)
      } catch (e) {
        console.log(e.message);
      }
    };

    getDashboardStats();
  }, [getAccessTokenSilently, user?.sub]);

  return { data, loading };
}

export default useDashboardStats
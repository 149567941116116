import React, { useState } from "react"
import { Button, Col, Container, Row } from "reactstrap"
import { useParams, Link } from "react-router-dom"

import BreadCrumb from "../../Components/Common/BreadCrumb"
import Loader from "../../Components/Common/Loader"
import useAccount from "../../hooks/useAccount"
import OverviewStatsBar from "../../Components/OverviewStatsBar"
import CallTypePieChart from "../../Components/Charts/Account/CallTypePieChart"
import LeadSourcePieChart from "../../Components/Charts/Account/LeadSourcePieChart"
import AgentOverviewChart from "../../Components/Charts/Account/AgentOverviewChart"
import RevenueOverviewCard from "../../Components/RevenueOverviewCard"
import RecentActivityCard from "../../Components/RecentActivityCard"
import CreateAccountReportModal from "../../modals/CreateAccountReportModal"
import TopDispositionsCard from "../../Components/TopDispositionsCard"

const apiDomain = process.env.REACT_APP_F2G_API_DOMAIN

const Account = () => {
  const { id } = useParams()
  const { data: account, loading: accountLoading } = useAccount(id, true)
  const [reportModalOpen, setReportModalOpen] = useState(false)
  console.log(account)

  document.title = "Account Settings"
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb parentTitle="Accounts" parentLink="/dashboard/accounts" childLabel={account.name} title={account.name} />
          <Row>
            <Col>
              <div className="h-100">
                <Row>
                  {accountLoading && (
                    <Loader />
                  )}

                  {!accountLoading && (
                    <div>
                      <div className="row">
                        <div className="col-sm-4 col-md-3 col-xl-2">
                          {account.logo && (
                            <div className="text-center img-responsive">
                              <div className="f2g-logo" style={{
                                backgroundImage: `url("${apiDomain}/account-logos/${account.logo}")`,
                                margin: '0 auto'
                              }}>&nbsp;</div>
                            </div>
                          )}
                          <div className="account-settings-button-wrapper">
                            <Link to={`/dashboard/accounts/${account.id}/settings`} className="btn btn-ghost-primary">Account Settings</Link><br />
                            <button className="btn btn-ghost-primary" onClick={() => setReportModalOpen(true)}>Generate Report</button>
                          </div>
                        </div>
                        {/* <div className="col-sm-2">
                          <div className="card">
                            <div className="card-header"><strong>Quick Links</strong></div>
                            <div className="card-body">
                              <Link to={`/dashboard/accounts/${account.id}/settings`}>Account Settings</Link>
                            </div>
                          </div>
                        </div> */}
                        <div className="col-sm-8 col-md-9 col-xl-10">
                          <OverviewStatsBar {...account.stats} />
                        </div>
                        
                      </div>
                      <div className="row">
                        <div className="col-sm-9">
                          <TopDispositionsCard
                            topInboundDispositions={account?.stats?.topInboundDispositions}
                            topOutboundDispositions={account?.stats?.topOutboundDispositions}
                          />
                          {/* <RevenueOverviewCard
                            totalRevenue={account?.stats?.totalRevenue}
                            totalRevenueAssisted={account?.stats?.totalRevenueAssisted}
                            percentageRevenueAssisted={account?.stats?.percentageRevenueAssisted}
                            revenueData={account?.stats?.revenueOverview}
                          /> */}
                        </div>
                        <div className="col-sm-3">
                          <RecentActivityCard
                            events={account?.stats?.recentActivity}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="card">
                            <div className="card-header">
                              <h4 className="card-title mb-0">Agent Overview</h4>
                            </div>
                            <div className="card-body">
                              <AgentOverviewChart data={account?.stats?.agentOverview} dataColors='["--vz-primary", "--vz-success", "--vz-warning", "--vz-danger", "--vz-info"]'/>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-3">
                          <div className="card">
                            <div className="card-header">
                              <h4 className="card-title mb-0">Call Breakdown</h4>
                            </div>
                            <div className="card-body">
                              <CallTypePieChart callTypes={account?.stats?.callTypes} dataColors='["--vz-primary", "--vz-success", "--vz-warning", "--vz-danger", "--vz-info"]'/>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-3">
                          <div className="card">
                            <div className="card-header">
                              <h4 className="card-title mb-0">Lead Source Breakdown</h4>
                            </div>
                            <div className="card-body">
                              <LeadSourcePieChart leadSources={account?.stats?.leadSources} dataColors='["--vz-primary", "--vz-success", "--vz-warning", "--vz-danger", "--vz-info"]'/>
                            </div>
                          </div>
                        </div>
                      </div>
                      
                    </div>
                  )}
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <CreateAccountReportModal open={reportModalOpen} setOpen={setReportModalOpen} account={account} />
    </React.Fragment>
  );
};

export default Account;

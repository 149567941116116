import React from 'react'

import ParticlesAuth from "../AuthenticationInner/ParticlesAuth"
import withRouter from '../../Components/Common/withRouter'
import DailyWrapUpModal from '../../modals/DailyWrapUpModal'

const DailyWrapUp = () => {
  document.title = "Daily Wrap Up"
  return (
    <React.Fragment>
      <ParticlesAuth>
        <DailyWrapUpModal />
      </ParticlesAuth>
    </React.Fragment>
  )
}

export default withRouter(DailyWrapUp)
import { useAuth0 } from "@auth0/auth0-react"
import React from "react"
import { Container } from "reactstrap"
import BreadCrumb from "../../Components/Common/BreadCrumb"
import useDashboardStats from "../../hooks/useDashboardStats"
import Loader from "../../Components/Common/Loader"
import OverviewStatsBar from "../../Components/OverviewStatsBar"
import RevenueOverviewCard from "../../Components/RevenueOverviewCard"
import RecentActivityCard from "../../Components/RecentActivityCard"
import TopDispositionsCard from "../../Components/TopDispositionsCard"

const Dashboard = () => {
  const { user } = useAuth0()
  const { data } = useDashboardStats()

  const hour = new Date().getHours()
  let timeOfDay = 'morning'
  if (hour >= 12 && hour < 17) {
    timeOfDay = 'afternoon'
  } else if ((hour >= 17 && hour < 24) || hour < 4) (
    timeOfDay = 'evening'
  )

  document.title = "Dashboard";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Dashboard" />
          <div className="row mb-3 pb-1">
            <div className="col-12">
              <div className="d-flex align-items-lg-center flex-lg-row flex-column">
                <div className="flex-grow-1">
                  <h4 className="fs-16 mb-1">Good {timeOfDay}, {user.given_name}!</h4>
                  <p className="text-muted mb-0">Here's what's happening today.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            {data && <OverviewStatsBar {...data} />}
          </div>
          <div className="row">
            <div className="col-sm-9">
              {data && (
                <TopDispositionsCard
                  topInboundDispositions={data?.topInboundDispositions}
                  topOutboundDispositions={data?.topOutboundDispositions}
                />
              )}
            </div>
            <div className="col-sm-3">
              <RecentActivityCard events={data?.recentActivity} />
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;

import React from "react";
import ReactApexChart from "react-apexcharts";
import { getChartColorsArray } from "../../../helpers/chartColorHelper";

const AgentOverviewChart = ({ data, dataColors }) => {
  var chartStackedBarColors = getChartColorsArray(dataColors);

  const agents = Array.from(new Set(data.map(x => x.agentName)))
  const callTypes = Array.from(new Set(data.map(x => x.callType)))

  const callTypeMap = data.reduce((prev, curr) => {
    prev[`${curr.agentName}-${curr.callType}`] = curr.callCount
    return prev
  }, {})

  let series = callTypes.map(callType => {
    const dater = agents.map(agent => {
      if (!!callTypeMap[`${agent}-${callType}`]) return callTypeMap[`${agent}-${callType}`]
      return 0
    })

    return {
      name: callType,
      data: dater
    }
  })

  var options = {
      chart: {
          type: 'bar',
          height: 350,
          stacked: true,
          toolbar: {
              show: false,
          }
      },
      plotOptions: {
          bar: {
              horizontal: true,
          },
      },
      stroke: {
          width: 1,
          colors: ['#fff']
      },
      xaxis: {
          categories: agents,
      },
      fill: {
          opacity: 1
      },
      legend: {
          position: 'bottom',
          horizontalAlign: 'left',
          offsetX: 40
      },
      colors: chartStackedBarColors,
  };

  return (
      <React.Fragment>
          <ReactApexChart
              className="apex-charts"
              options={options}
              series={series}
              type="bar"
              height={350}
          />
      </React.Fragment>
  );
};

export default AgentOverviewChart
import React from 'react';
import { Card, CardBody, Col, Container, Row, Button } from 'reactstrap';
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import { Link } from "react-router-dom";

import logoDark from "../../assets/images/logo-dark.png";
import withRouter from '../../Components/Common/withRouter';
import { useAuth0 } from '@auth0/auth0-react';

const Login = () => {
	const { loginWithRedirect } = useAuth0()

	document.title = "Login";
	return (
		<React.Fragment>
			<ParticlesAuth>
				<div className="auth-page-content">
					<Container>
						<Row>
							<Col lg={12}>
								<div className="text-center mt-sm-5 mb-4 text-white-50">
									<div>
										<Link to="/" className="d-inline-block auth-logo">
											<img src={logoDark} alt="" height="80" />
										</Link>
									</div>
								</div>
							</Col>
						</Row>

						<Row className="justify-content-center">
							<Col md={8} lg={6} xl={5}>
								<Card className="mt-4">
									<CardBody className="p-4">
										<div className="text-center mt-2">
											<h5 className="text-primary">Welcome back!</h5>
											<p className="text-muted">Sign in to continue to Free2Grow.</p>
										</div>
										<div className="p-2 mt-4">
											<div className="mt-4">
												<Button color="primary" onClick={() => loginWithRedirect()} className="btn btn-primary w-100" type="submit">Continue to Sign In</Button>
											</div>
										</div>
									</CardBody>
								</Card>
							</Col>
						</Row>
					</Container>
				</div>
			</ParticlesAuth>
		</React.Fragment>
	);
};

export default withRouter(Login);
import React from "react";
import { Navigate, Route } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

const AuthProtected = (props) => {
  const { isAuthenticated, isLoading } = useAuth0()

  if (isLoading) {
    return <div />
  }

  if (!isAuthenticated) {
    return <Navigate to={{ pathname: "/login", state: { from: props.location } }} />
  }

  return <>{props.children}</>
};

const AccessRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        return (<> <Component {...props} /> </>);
      }}
    />
  );
};

export { AuthProtected, AccessRoute };
import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { useParams } from 'react-router-dom'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, Label, Table } from 'reactstrap'
import axios from 'axios'
import moment from 'moment'

import useDailyWrapUp from '../hooks/useDailyWrapUp'

const f2gDomain = process.env.REACT_APP_F2G_API_DOMAIN

function DailyWrapUpModal() {
  const { secret } = useParams()
  const { accounts, name, loading, isNotFound } = useDailyWrapUp(secret)

  const [stage, setStage] = useState('init')
  const [date, setDate] = useState(moment().format("YYYY-MM-DD"))
  const [accountCheckedMap, setAccountCheckedMap] = useState({})

  useEffect(() => {
    if (!loading && accounts?.length > 0) {
      setStage('init')
      setAccountCheckedMap(_.keyBy(accounts.map(account => ({
        ...account,
        checked: false,
        sms: '',
        smsNote: '',
        email: '',
        emailNote: '',
      })), 'id'))
    }
  }, [loading, accounts])

  useEffect(() => {
    if (isNotFound) {
      window.location.replace('https://www.free-2-grow.com')
    }
  }, [isNotFound])

  const onChangeAccount = (accountId, isChecked) => {
    setAccountCheckedMap({
      ...accountCheckedMap,
      [accountId]: {
        ...accountCheckedMap[accountId],
        checked: isChecked,
      }
    })
  }

  const handleBack = () => {
    if (stage === 'accountSelect') {
      setStage('init')
    } else if (stage === 'inputData') {
      setStage('accountSelect')
    }
  }

  const updateInputValue = (accountId, type, value) => {
    setAccountCheckedMap({
      ...accountCheckedMap,
      [accountId]: {
        ...accountCheckedMap[accountId],
        [type]: value,
      }
    })
  }

  const handleSubmit = () => {
    const interactions = Object
      .values(accountCheckedMap)
      .filter(account => account.checked === true)

    const body = {
      interactions,
      date
    }

    return axios.post(`${f2gDomain}/daily-wrap-up/${secret}`, body, {
      headers: {
        'Content-type': 'application/json',
      }
    }).then((data) => {
      if (data?.isSuccess) {
        setStage('done')
      }
    }).catch((err) => {
      console.log(err)
    })
  }

  const reset = () => {
    const alteredAccounts = Object
      .values(accountCheckedMap)
      .filter(account => account.checked === true || account.sms !== '' || account.email !== '')

    for (const account of alteredAccounts) {
      setAccountCheckedMap({
        ...accountCheckedMap,
        [account.id]: {
          ...accountCheckedMap[account.id],
          checked: false,
          sms: '',
          smsNote: '',
          email: '',
          emailNote: ''
        }
      })
    }
    setStage('init')
  }

  const accountSelectIsReadyToSubmit = Object
    .values(accountCheckedMap)
    .filter(account => account.checked === true).length > 0

  const inputDataInvalid = Object
    .values(accountCheckedMap)
    .filter(account => account.checked === true)
    .some(account => account.sms === '' || account.email === '')

  let title = ''
  if (loading) {
    title = 'Loading...'
  } else if (stage === 'init') {
    title = `Hi ${name}!`
  } else if (stage === 'accountSelect') {
    title = 'Which accounts did you assist with tasks today?'
  } else if (stage === 'inputData') {
    title = 'How many interactions did you have today with customers?'
  } else if (stage === 'done') {
    title = 'Thank you!'
  }

  const accountOptions = Object.keys(accountCheckedMap).map((id) => ({
    id,
    name: accountCheckedMap[id].name,
    checked: accountCheckedMap[id].checked
  })).sort(function (a, b) {
    return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
})

  return (
    <span>
      <Modal isOpen={true}>
        <ModalHeader>{title}</ModalHeader>
        <ModalBody>
          {stage === 'loading' && (
            <div>Loading...</div>
          )}
          {stage === 'init' && (
            <div>
              <p>Time to submit your daily wrap-up. Please select the date you are recording for, and click <code>Next</code> to continue.</p>
              <Input type="date" defaultValue={date} onChange={(e) => setDate(e.target.value)} />
            </div>
          )}
          {stage === 'accountSelect' && (
            <div>
              {accountOptions.map(({ id, name, checked }) => (
                <div key={id}>
                  <Label key={id}>
                    <Input
                      type="checkbox"
                      checked={checked}
                      onChange={(e) => onChangeAccount(id, e.target.checked)} />{' '}{name}
                  </Label>
                </div>
              ))}
            </div>
          )}
          {stage === 'inputData' && (
            <div>
              <p>Please provide the number of texts and emails that were exchanged for each client today.</p>
              <Table>
                <thead>
                  <tr>
                    <th>Account</th>
                    <th>Text</th>
                    <th>Email</th>
                  </tr>
                </thead>
                <tbody>
                {Object.keys(accountCheckedMap).map((accountId) => {
                  if (!accountCheckedMap[accountId].checked) return null
                  return (
                    <tr key={accountId}>
                      <td>{accountCheckedMap[accountId].name}</td>
                      <td>
                        <Input type="text" defaultValue={accountCheckedMap[accountId].sms} onChange={(e) => updateInputValue(accountId, 'sms', e.target.value)} />
                      </td>
                      <td>
                        <Input type="text" defaultValue={accountCheckedMap[accountId].email} onChange={(e) => updateInputValue(accountId, 'email', e.target.value)} />
                      </td>
                    </tr>
                  )
                })}
                </tbody>
              </Table>
            </div>
          )}
          {stage === 'done' && (
            <div>Your tasks were saved. You can close this tab.</div>
          )}
        </ModalBody>
        <ModalFooter>
          {!(['init', 'done'].includes(stage)) && (
            <Button size="lg" color="primary" disabled={stage === 'init'} onClick={() => handleBack()}>Back</Button>
          )}
          {stage === 'init' && (
            <Button size="lg" color="primary" onClick={() => setStage('accountSelect')}>Next</Button>
          )}
          {stage === 'accountSelect' && (
            <Button size="lg" color="primary" disabled={!accountSelectIsReadyToSubmit} onClick={() => setStage('inputData')}>Next</Button>
          )}
          {stage === 'inputData' && (
            <Button size="lg" color="primary" disabled={inputDataInvalid} onClick={() => handleSubmit(false)}>Submit</Button>
          )}
          {stage === 'done' && (
            <Button size="lg" color="primary" onClick={() => reset()}>Start Again</Button>
          )}
        </ModalFooter>
      </Modal>
    </span>
  );
}

export default DailyWrapUpModal
import React from "react"
import { Badge, Col, Container, Input, Row } from "reactstrap"
import moment from "moment"

import BreadCrumb from "../../Components/Common/BreadCrumb"
import Loader from "../../Components/Common/Loader"
import useDailyWrapUpSummary from "../../hooks/useDailyWrapUpSummary"
import { useAuth0 } from "@auth0/auth0-react"
import { Link } from "react-router-dom"

const domain = process.env.REACT_APP_AUTH0_DOMAIN
const apiDomain = process.env.REACT_APP_F2G_API_DOMAIN

const DailyWrapUpAdmin = () => {
  const { getAccessTokenSilently } = useAuth0()
  const { data, loading } = useDailyWrapUpSummary()

  const onUpdateDailyTask = async (employeeId, isChecked) => {
    try {
      const accessToken = await getAccessTokenSilently({
        audience: `https://${domain}/api/v2/`,
        scope: "read:current_user",
      });

      await fetch(`${apiDomain}/daily-wrap-up/admin/${isChecked ? 'enable' : 'disable'}/${employeeId}`, {
        method: 'POST',
        headers: {
          "Authorization": `Bearer ${accessToken}`,
          "Content-Type": 'application/json',
        },
      })
    } catch (e) {
      console.log(e)
    }
  }

  document.title = "Daily Wrap-ups"
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb parentTitle="Dashboard" parentLink="/dashboard" title="Daily Wrap-ups" childLabel="Daily Wrap-ups" />
          {loading && <Loader />}
          <Row>
            <Col sm="8">
              {(!loading && data?.activity) && (
                <div className="card">
                  <div className="card-header"><strong>Recent Email/SMS Interactions</strong></div>
                  <table className="table align-middle table-nowrap mb-0 table-responsive">
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Account</th>
                        <th>Employee</th>
                        <th>Type</th>
                        <th>Tasks</th>
                        {/* <th>Details</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {data?.activity.map((activity, index) => (
                        <tr key={index}>
                          <td>{moment(activity.createdAt).format("MM/DD/YY")}</td>
                          <td>{activity.accountName}</td>
                          <td>{activity.employeeName}</td>
                          <td>{activity.type}</td>
                          <td>{activity.tasksCompleted}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </Col>
            <Col sm="4">
              {(!loading && data?.employees) && (
                <div className="card">
                  {/* <div className="card-header"><strong>Employees</strong></div> */}
                  <table className="table align-middle table-nowrap mb-0 table-responsive">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Enabled</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.employees.map(employee => (
                        <tr key={employee.id}>
                          <td>
                            {employee.isDailyTaskEnabled && (
                              <a href={`/daily-wrap-up/${employee.secret}`} target="_blank" rel="noreferrer">{employee.name}</a>
                            )}
                            {!employee.isDailyTaskEnabled && (
                              <span>{employee.name}</span>
                            )}
                          </td>
                          <td>
                            <Input type="checkbox" defaultChecked={employee.isDailyTaskEnabled} onChange={(e) => onUpdateDailyTask(employee.id, e.target.checked)} />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default DailyWrapUpAdmin

import React, { useState } from "react";
import {
  Form,
  FormGroup,
  Label,
  Col,
  Input,
  FormFeedback
} from "reactstrap";

const CreateIntegrationForm = ({
  integration: integrationTemplate,
  loading,
  errors,
  onSubmit,
  formId,
}) => {
  const [integration, setIntegration] = useState(!!integrationTemplate ? integrationTemplate : {
    id: null,
    accountId: null,
    service: 'housecallpro',
    clientId: '',
    secretKey: ''
  })

  const errorMap = errors.reduce((prev, curr) => {
    prev[curr.param] = curr.msg
    return prev
  }, {})

  return (
    <Form id={formId} onSubmit={(e) => onSubmit(e, integration)}>
      <FormGroup row>
        <Label
          for={`${formId}Service`}
          sm={3}
        >
          Service
        </Label>
        <Col sm={9}>

          <Input
            id={`${formId}Service`}
            name="service"
            placeholder="Service name"
            type="select"
            value={integration.service}
            disabled={loading}
            onChange={(e) => setIntegration({...integration, service: e.target.value })}
            invalid={!!errorMap.service}
          >
            <option value="housecallpro">HouseCall Pro</option>
          </Input>
          <FormFeedback>{errorMap.service}</FormFeedback>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label
          for={`${formId}ClientId`}
          sm={3}
        >
          Client ID
        </Label>
        <Col sm={9}>
          <Input
            id={`${formId}ClientId`}
            name="clientId"
            placeholder="Client ID"
            type="text"
            value={integration.clientId}
            disabled={loading}
            onChange={(e) => setIntegration({...integration, clientId: e.target.value })}
            invalid={!!errorMap.clientId}
          />
          <FormFeedback>{errorMap.clientId}</FormFeedback>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label
          for={`${formId}SecretKey`}
          sm={3}
        >
          Secret Key
        </Label>
        <Col sm={9}>
          <Input
            id={`${formId}SecretKey`}
            name="secretKey"
            placeholder="Secret Key"
            type="text"
            value={integration.secretKey}
            disabled={loading}
            onChange={(e) => setIntegration({...integration, secretKey: e.target.value })}
            invalid={!!errorMap.secretKey}
          />
          <FormFeedback>{errorMap.secretKey}</FormFeedback>
        </Col>
      </FormGroup>
    </Form>
  )
}

export default CreateIntegrationForm
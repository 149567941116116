import React from 'react'

const OverviewStatsBar = ({
  totalCustomers,
  totalTalkTime,
  avgCallsPerCustomer,
  avgPerCustomer,
  maxCallLength,
}) => {
  return (
    <div className="row">
      <div className="col-xl-12">
        <div className="card crm-widget">
          <div className="card-body p-0">
            <div className="row row-cols-xxl-5 row-cols-md-2 row-cols-1 g-0">
              <div className="col">
                <div className="py-4 px-3">
                  <h5 className="text-muted text-uppercase fs-13">
                    Unique Customers{' '}
                    {/* <i className="ri-arrow-up-circle-line text-success fs-18 float-end align-middle"></i> */}
                  </h5>
                  <div className="d-flex align-items-center">
                    <div className="flex-shrink-0">
                      <i className="ri-user-fill display-6 text-muted"></i>
                    </div>
                    <div className="flex-grow-1 ms-3">
                      <h2 className="mb-0">
                        {/* <span className="counter-value" data-target="197"> */}
                          {totalCustomers}
                        {/* </span> */}
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="mt-3 mt-md-0 py-4 px-3">
                  <h5 className="text-muted text-uppercase fs-13">
                    Total Talk Time{' '}
                    {/* <i className="ri-arrow-up-circle-line text-success fs-18 float-end align-middle"></i> */}
                  </h5>
                  <div className="d-flex align-items-center">
                    <div className="flex-shrink-0">
                      <i className="ri-timer-2-fill display-6 text-muted"></i>
                    </div>
                    <div className="flex-grow-1 ms-3">
                      <h2 className="mb-0">
                        {/* <span className="counter-value" data-target="100"> */}
                          {totalTalkTime?.hours || '0'} hr {totalTalkTime?.minutes || '0'} min
                        {/* </span> */}
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="mt-3 mt-md-0 py-4 px-3">
                  <h5 className="text-muted text-uppercase fs-13">
                    Avg Time per Customer{' '}
                    {/* <i className="ri-arrow-down-circle-line text-danger fs-18 float-end align-middle"></i> */}
                  </h5>
                  <div className="d-flex align-items-center">
                    <div className="flex-shrink-0">
                      <i className="ri-pulse-line display-6 text-muted"></i>
                    </div>
                    <div className="flex-grow-1 ms-3">
                      <h2 className="mb-0">
                        {/* <span className="counter-value" data-target="32.89"> */}
                          {avgPerCustomer?.minutes ? `${avgPerCustomer?.minutes} min` : ''} {avgPerCustomer?.seconds ? `${avgPerCustomer?.seconds} sec` : ''}
                        {/* </span> */}
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="mt-3 mt-lg-0 py-4 px-3">
                  <h5 className="text-muted text-uppercase fs-13">
                    Avg Calls Per Customer{' '}
                    {/* <i className="ri-arrow-up-circle-line text-success fs-18 float-end align-middle"></i> */}
                  </h5>
                  <div className="d-flex align-items-center">
                    <div className="flex-shrink-0">
                      <i className="ri-phone-fill display-6 text-muted"></i>
                    </div>
                    <div className="flex-grow-1 ms-3">
                      <h2 className="mb-0">
                        <span className="counter-value" data-target="1596.5">
                          {parseFloat(avgCallsPerCustomer || 0).toFixed(2)}
                        </span>
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="mt-3 mt-lg-0 py-4 px-3">
                  <h5 className="text-muted text-uppercase fs-13">
                    Longest Call Time{' '}
                    {/* <i className="ri-arrow-up-circle-line text-success fs-18 float-end align-middle"></i> */}
                  </h5>
                  <div className="d-flex align-items-center">
                    <div className="flex-shrink-0">
                      <i className="ri-timer-fill display-6 text-muted"></i>
                    </div>
                    <div className="flex-grow-1 ms-3">
                      <h2 className="mb-0">
                        {/* <span className="counter-value" data-target="1596.5"> */}
                          {maxCallLength?.minutes || 'N/A'} min {maxCallLength?.seconds ? `${maxCallLength?.seconds} sec` : ''}
                        {/* </span> */}
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="col">
                <div className="mt-3 mt-lg-0 py-4 px-3">
                  <h5 className="text-muted text-uppercase fs-13">Annual Deals <i className="ri-arrow-down-circle-line text-danger fs-18 float-end align-middle"></i></h5>
                  <div className="d-flex align-items-center">
                    <div className="flex-shrink-0">
                      <i className="ri-service-line display-6 text-muted"></i>
                    </div>
                    <div className="flex-grow-1 ms-3">
                      <h2 className="mb-0"><span className="counter-value" data-target="2659">2,659</span></h2>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OverviewStatsBar
import React, { useState } from "react";
import { Col, Container, Row, FormGroup, Input } from "reactstrap";
import { useParams, Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import BreadCrumb from "../../Components/Common/BreadCrumb";
import Loader from "../../Components/Common/Loader";
import CreateOrUpdateModal from "../../modals/CreateOrUpdateModal";
import SubmitFormButton from "../../Components/Forms/SubmitFormButton";
import CreateContactForm from "../../Components/Forms/CreateContactForm";
import CreateIntegrationForm from "../../Components/Forms/CreateIntegrationForm";
import EditAccountModal from "../../modals/EditAccountModal";
import DeleteListItemButton from "../../Components/Forms/DeleteListItemButton";
import useAccount from "../../hooks/useAccount";
// import useHouseCallProCustomers from "../../hooks/useHouseCallProCustomers";
// import useHouseCallProJobs from "../../hooks/useHouseCallProJobs";

const domain = process.env.REACT_APP_AUTH0_DOMAIN
const apiDomain = process.env.REACT_APP_F2G_API_DOMAIN

const Account = () => {
  const { id } = useParams()
  const { getAccessTokenSilently } = useAuth0()
  const { data: account, loading } = useAccount(id)

  const [editAccountModalOpen, setEditAccountModalOpen] = useState(false)

  const [editContact, setEditContact] = useState(null)
  const [editIntegration, setEditIntegration] = useState(null)

  // Contacts
  const [createContactFormOpen, setCreateContactFormOpen] = useState(false)
  const [saveContactLoading, setSaveContactLoading] = useState(false)
  const [saveContactErrors, setSaveContactErrors] = useState([])

  // Integrations
  const [createIntegrationFormOpen, setCreateIntegrationFormOpen] = useState(false)
  const [saveIntegrationLoading, setSaveIntegrationLoading] = useState(false)
  const [saveIntegrationErrors, setSaveIntegrationErrors] = useState([])

  // Account logo
  const [accountLogo, setAccountLogo] = useState(null)

  const onSubmitContact = async (e, contact) => {
    e.preventDefault()
    setSaveContactLoading(true)

    try {
      const accessToken = await getAccessTokenSilently({
        audience: `https://${domain}/api/v2/`,
        scope: "read:current_user",
      });

      const accountResponse = await fetch(`${apiDomain}/accounts/${id}/contacts`, {
        method: 'POST',
        headers: {
          "Authorization": `Bearer ${accessToken}`,
          "Content-Type": 'application/json',
        },
        body: JSON.stringify(contact)
      });

      const { data, errors } = await accountResponse.json()
      setSaveContactLoading(false)

      if (errors && errors.length > 0) {
        setSaveContactErrors(errors)
        return
      }

      setSaveContactErrors([])
      setCreateContactFormOpen(false)
      window.location.reload()
    } catch (e) {
      console.log(e)
    }
  }

  const onSubmitIntegration = async (e, integration) => {
    e.preventDefault()
    setSaveIntegrationLoading(true)

    try {
      const accessToken = await getAccessTokenSilently({
        audience: `https://${domain}/api/v2/`,
        scope: "read:current_user",
      });

      const accountResponse = await fetch(`${apiDomain}/accounts/${id}/integrations`, {
        method: 'POST',
        headers: {
          "Authorization": `Bearer ${accessToken}`,
          "Content-Type": 'application/json',
        },
        body: JSON.stringify(integration)
      });

      const { errors } = await accountResponse.json()
      setSaveIntegrationLoading(false)

      if (errors && errors.length > 0) {
        setSaveIntegrationErrors(errors)
        return
      }

      setSaveIntegrationErrors([])
      setSaveIntegrationLoading(false)
      window.location.reload()
    } catch (e) {
      console.log(e)
    }
  }

  const onFileChange = (e) => {
    setAccountLogo(e.target.files[0])
  }

  const onFileUpload = async () => {
    const accessToken = await getAccessTokenSilently({
      audience: `https://${domain}/api/v2/`,
      scope: "read:current_user",
    });

    const formData = new FormData();

    formData.append(
      "avatar",
      accountLogo,
      accountLogo.name
    );

    await fetch(`${apiDomain}/accounts/${account.id}/logo`, {
      method: 'POST',
      headers: {
        "Authorization": `Bearer ${accessToken}`,
      },
      body: formData
    });

    window.location.reload()
  }

  const createContactFormId = 'createContactForm'
  const createIntegrationFormId = 'createIntegrationForm'

  document.title = "Account"
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb parentTitle="Accounts" parentLink="/dashboard/accounts" title={account.name} childLabel="Settings" />
          <Row>
            <Col>
              <div className="h-100">
                <Row>
                  {loading && (
                    <Loader />
                  )}

                  {!loading && (
                    <div>
                      <div className="row">
                        <div className="col-sm-12 text-left">
                          <Link to={`/dashboard/accounts/${account.id}`} className="btn btn-ghost-primary">
                            <i className="ri-arrow-left-circle-fill"></i>{' '}
                              Back to {account.name}
                          </Link>
                          <br /><br />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="card">
                            <div className="card-body">
                              <div className="row">
                                <div className="col-sm-6">
                                  <div className="row">
                                    <div className="col-sm-12">
                                      <p><strong>Location</strong><br />{account.street} {account.city}, {account.state} {account.zip}</p>
                                      <p><strong>Created</strong><br />{account.createdAt}</p>
                                      <p><strong>Updated</strong><br />{account.updatedAt}</p>
                                      <strong>Logo</strong><br /><br />
                                      {account.logo && (
                                        <div style={{ backgroundImage: `url(${apiDomain}/account-logos/${account.logo})` }} className="f2g-logo"></div>
                                      )}
                                      {!account.logo && (
                                        <p>No logo yet. Upload one below</p>
                                      )}

                                      <FormGroup>
                                        <Input
                                          id="logoFile"
                                          name="file"
                                          type="file"
                                          onChange={onFileChange}
                                        />
                                      </FormGroup>
                                      <button className="btn btn-primary" onClick={onFileUpload}>Upload</button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <hr />
                              <div className="row">
                                <div className="col-sm-6">
                                  <button onClick={() => setEditAccountModalOpen(true)} type="button" className="btn btn-sm btn-soft-secondary waves-effect waves-light">
                                    <i className="ri-pencil-line"></i>{' '}
                                    Edit Account
                                  </button>{' '}
                                  <EditAccountModal account={account} open={editAccountModalOpen} setOpen={setEditAccountModalOpen} />
                                  <DeleteListItemButton id={account.id} noun="account" endpoint={`${apiDomain}/accounts/${account.id}`}>
                                    <i className="ri-delete-bin-line"></i>{' '}Delete Account
                                  </DeleteListItemButton>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="row">
                            <div className="col-sm-12">
                              <div className="card">
                                <div className="card-header align-items-center d-flex">
                                  <h4 className="card-title mb-0 flex-grow-1">Contacts</h4>
                                  <div className="flex-shrink-0">
                                    <button onClick={() => setCreateContactFormOpen(true)} type="button" className="btn btn-sm btn-soft-secondary waves-effect waves-light">
                                      <i className="ri-add-fill"></i>{' '}Add Contact
                                    </button>
                                    <CreateOrUpdateModal
                                      form={
                                        <CreateContactForm
                                          contact={editContact ? editContact : null}
                                          loading={saveContactLoading}
                                          errors={saveContactErrors}
                                          onSubmit={onSubmitContact}
                                          formId={createContactFormId}
                                          open={createContactFormOpen}
                                          setOpen={setCreateContactFormOpen}
                                        />
                                      }
                                      open={createContactFormOpen}
                                      setOpen={setCreateContactFormOpen}
                                      submitButton={<SubmitFormButton loading={saveContactLoading} formId={createContactFormId} />}
                                      title="Add Contact"
                                    />
                                  </div>
                                </div>
                                {account.contacts.length > 0 && (
                                  <div className="card-body">
                                    <div className="table-responsive table-card">
                                      <table className="table table-borderless table-hover table-nowrap align-middle mb-0">
                                        <thead className="table-light">
                                          <tr className="text-muted">
                                            <th scope="col">Name</th>
                                            <th scope="col">Email</th>
                                            <th scope="col">Phone Number</th>
                                            <th scope="col" style={{ width: '5%' }}></th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {account.contacts.map((contact, index) => {
                                            return (
                                              <tr key={index}>
                                                <td>{contact.firstName} {contact.lastName}</td>
                                                <td>{contact.email}</td>
                                                <td>{contact.phone}</td>
                                                <td>
                                                  <div className="btn-group">
                                                    <button className="btn btn-sm btn-light" type="button" onClick={() => { setEditContact(contact); setCreateContactFormOpen(true); }}>
                                                      <i className="ri-pencil-line"></i>
                                                    </button>
                                                    <DeleteListItemButton id={contact.id} noun="contact" endpoint={`${apiDomain}/accounts/${account.id}/contacts/${contact.id}`}>
                                                      <i className="ri-delete-bin-line"></i>
                                                    </DeleteListItemButton>
                                                  </div>
                                                </td>
                                              </tr>
                                            )
                                          })}
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                )}
                                <div className="card-footer">
                                  <span className="text-muted" style={{ fontSize: '12px' }}>
                                    {account.contacts.length > 0 ? `${account.contacts.length} contact(s) found` : 'No contacts found.'}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-12">
                              <div className="card">
                                <div className="card-header align-items-center d-flex">
                                  <h4 className="card-title mb-0 flex-grow-1">Integrations</h4>
                                  <div className="flex-shrink-0">
                                    <button onClick={() => setCreateIntegrationFormOpen(true)} type="button" className="btn btn-sm btn-soft-secondary waves-effect waves-light">
                                      <i className="ri-add-fill"></i>{' '}Add Integration
                                    </button>
                                    <CreateOrUpdateModal
                                      form={
                                        <CreateIntegrationForm
                                          integration={editIntegration ? editIntegration : null}
                                          loading={saveIntegrationLoading}
                                          errors={saveIntegrationErrors}
                                          onSubmit={onSubmitIntegration}
                                          formId={createIntegrationFormId}
                                          open={createIntegrationFormOpen}
                                          setOpen={setCreateIntegrationFormOpen}
                                        />
                                      }
                                      open={createIntegrationFormOpen}
                                      setOpen={setCreateIntegrationFormOpen}
                                      submitButton={<SubmitFormButton loading={saveIntegrationLoading} formId={createIntegrationFormId} />}
                                      title="Add Integration"
                                    />
                                  </div>
                                </div>
                                {account.integrations.length > 0 && (
                                  <div className="card-body">
                                    <div className="table-responsive table-card">
                                      <table className="table table-borderless table-hover table-nowrap align-middle mb-0">
                                        <thead className="table-light">
                                          <tr className="text-muted">
                                            <th scope="col" style={{ width: '20%' }}>Service</th>
                                            <th scope="col" style={{ width: '30%' }}>Client ID</th>
                                            <th scope="col" style={{ width: '20%' }}>Secret Key</th>
                                            <th scope="col" style={{ width: '5%' }}></th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {account.integrations.map((integration, index) => {
                                            return (
                                              <tr key={index}>
                                                <td>{integration.service}</td>
                                                <td>{integration.clientId}</td>
                                                <td>{integration.secretKey}</td>
                                                <td>
                                                  <div className="btn-group">
                                                    <button className="btn btn-sm btn-light" type="button" onClick={() => { setEditIntegration(integration); setCreateIntegrationFormOpen(true); }}>
                                                      <i className="ri-pencil-line"></i>
                                                    </button>
                                                    <DeleteListItemButton id={integration.id} noun="integration" endpoint={`${apiDomain}/accounts/${account.id}/integrations/${integration.id}`}>
                                                      <i className="ri-delete-bin-line"></i>
                                                    </DeleteListItemButton>
                                                  </div>
                                                </td>
                                              </tr>
                                            )
                                          })}
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                )}
                                <div className="card-footer">
                                  <span className="text-muted" style={{ fontSize: '12px' }}>
                                    {account.integrations.length > 0 ? `${account.integrations.length} integration(s) found` : 'No integrations found.'}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      
                      {/* <div className="row">
                        <div className="col-sm-12">
                          <div className="card">
                            <div className="card-header align-items-center d-flex">
                              <h4 className="card-title mb-0 flex-grow-1">Customers</h4>
                            </div>
                            <div className="card-body">
                              {!customersLoading && (
                                <table className="table table-responsive">
                                  <thead>
                                    <th>Name</th>
                                    <th>Lead Source</th>
                                    <th>Created</th>
                                  </thead>
                                  <tbody>
                                    {customers.map((customer) => (
                                      <tr key={customer.id}>
                                        <td>{customer.first_name} {customer.last_name}</td>
                                        <td>{customer.lead_source}</td>
                                        <td>{customer.created_at}</td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              )}
                              {customersLoading && (
                                <div>Loading...</div>
                              )}
                              
                              <ul className="pagination">
                                {customerPage - 3 > 0 && (
                                  <li className="page-item"><a className="page-link" href="#" onClick={(e) => { e.preventDefault(); setCustomerPage(customerPage-3); }}>{customerPage-3}</a></li>
                                )}
                                {customerPage - 2 > 0 && (
                                  <li className="page-item"><a className="page-link" href="#" onClick={(e) => { e.preventDefault(); setCustomerPage(customerPage-2); }}>{customerPage-2}</a></li>
                                )}
                                {customerPage - 1 > 0 && (
                                  <li className="page-item"><a className="page-link" href="#" onClick={(e) => { e.preventDefault(); setCustomerPage(customerPage-1); }}>{customerPage-1}</a></li>
                                )}
                                <li className="page-item active"><a className="page-link" href="#">{customerPage}</a></li>
                                {customerPage + 1 <= customersTotalResults && (
                                  <li className="page-item"><a className="page-link" href="#" onClick={(e) => { e.preventDefault(); setCustomerPage(customerPage+1); }}>{customerPage+1}</a></li>
                                )}
                                {customerPage + 2 <= customersTotalResults && (
                                  <li className="page-item"><a className="page-link" href="#" onClick={(e) => { e.preventDefault(); setCustomerPage(customerPage+2); }}>{customerPage+2}</a></li>
                                )}
                                {customerPage + 3 <= customersTotalResults && (
                                  <li className="page-item"><a className="page-link" href="#" onClick={(e) => { e.preventDefault(); setCustomerPage(customerPage+3); }}>{customerPage+3}</a></li>
                                )}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div> */}
                      <br /><br /><br /><br /><br /><br />
                      <br /><br /><br /><br /><br /><br />
                      <br /><br /><br /><br /><br /><br />
                    </div>
                  )}
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Account;

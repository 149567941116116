import React from "react";
import { Navigate } from "react-router-dom";

// Authentication Components
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import BasicLogout from '../pages/AuthenticationInner/Logout/BasicLogout';
import DailyWrapUp from '../pages/DailyWrapUp/DailyWrapUp'
import UserProfile from "../pages/Authentication/user-profile";

// Application Components
import Dashboard from "../pages/Dashboard/index";
import Accounts from "../pages/Dashboard/Accounts";
import Account from "../pages/Dashboard/Account";
import AccountSettings from "../pages/Dashboard/AccountSettings"
import UploadCalls from "../pages/Dashboard/UploadCalls"
import DailyWrapUpAdmin from "../pages/Dashboard/DailyWrapUpAdmin";

const authProtectedRoutes = [
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/dashboard/accounts/:id", component: <Account /> },
  { path: "/dashboard/accounts/:id/settings", component: <AccountSettings /> },
  { path: "/dashboard/accounts", component: <Accounts /> },
  { path: "/dashboard/upload-calls", component: <UploadCalls /> },
  { path: "/profile", component: <UserProfile /> },
  { path: "/dashboard/daily-wrap-ups", component: <DailyWrapUpAdmin /> },
  // { path: "*", component: <Navigate to="/dashboard" /> },
];

const publicRoutes = [
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/logged-out", component: <BasicLogout /> },
  { path: "/daily-wrap-up/:secret", component: <DailyWrapUp />},

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
  { path: "*", component: <Navigate to="/dashboard" /> },
];

export { authProtectedRoutes, publicRoutes };
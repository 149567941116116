import React, { useState } from "react";
import {
  Form,
  FormGroup,
  Label,
  Col,
  Input,
  FormFeedback
} from "reactstrap";
import { UsaStates } from "usa-states"

const { states } = new UsaStates()

const CreateAccountForm = ({
  account: accountTemplate,
  loading,
  errors,
  onSubmit,
  formId: formIdTemplate
}) => {
  const [account, setAccount] = useState(!!accountTemplate ? accountTemplate : {
    id: null,
    name: '',
    street: '',
    city: '',
    state: 'mo',
    zip: ''
  })
  const formId = !!formIdTemplate ? formIdTemplate : 'createAccountForm'

  const errorMap = errors.reduce((prev, curr) => {
    prev[curr.param] = curr.msg
    return prev
  }, {})

  return (
    <Form id={formId} onSubmit={(e) => onSubmit(e, account)}>
      <FormGroup row>
        <Label
          for={`${formId}Name`}
          sm={2}
        >
          Name
        </Label>
        <Col sm={10}>
          <Input
            id={`${formId}Name`}
            name="name"
            placeholder="Enter the account name"
            type="text"
            value={account.name}
            disabled={loading}
            onChange={(e) => setAccount({...account, name: e.target.value })}
            invalid={!!errorMap.name}
          />
          <FormFeedback>{errorMap.name}</FormFeedback>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label
          for={`${formId}Address`}
          sm={2}
        >
          Street
        </Label>
        <Col sm={10}>
          <Input
            id={`${formId}Street`}
            name="street"
            placeholder="Enter the street address"
            type="text"
            value={account.street}
            disabled={loading}
            onChange={(e) => setAccount({...account, street: e.target.value })}
            invalid={!!errorMap.street}
          />
          <FormFeedback>{errorMap.street}</FormFeedback>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label
          for={`${formId}City`}
          sm={2}
        >
          City
        </Label>
        <Col sm={10}>
          <Input
            id={`${formId}City`}
            name="street"
            placeholder="Enter the city"
            type="text"
            value={account.city}
            disabled={loading}
            onChange={(e) => setAccount({...account, city: e.target.value })}
            invalid={!!errorMap.city}
          />
          <FormFeedback>{errorMap.city}</FormFeedback>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label
          for={`${formId}State`}
          sm={2}
        >
          State
        </Label>
        <Col sm={10}>
          <Input
            id={`${formId}State`}
            name="street"
            placeholder="Enter the state"
            type="select"
            value={account.state}
            disabled={loading}
            onChange={(e) => setAccount({...account, state: e.target.value })}
            invalid={!!errorMap.state}
          >
            {states.map((state, index) => <option key={index} value={state.abbreviation}>{state.name}</option>)}
          </Input>
          <FormFeedback>{errorMap.state}</FormFeedback>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label
          for={`${formId}Zip`}
          sm={2}
        >
          Zip Code
        </Label>
        <Col sm={10}>
          <Input
            id={`${formId}Zip`}
            name="zip"
            placeholder="Enter the zip code"
            type="text"
            value={account.zip}
            disabled={loading}
            onChange={(e) => setAccount({...account, zip: e.target.value })}
            invalid={!!errorMap.zip}
          />
          <FormFeedback>{errorMap.zip}</FormFeedback>
        </Col>
      </FormGroup>
      <div className="row">
        <div className="col-sm-6">
          {account.createdAt && (
            <FormGroup row>
              <Label
                for={`${formId}CreatedAt`}
              >
                Created
              </Label>
              <Col>
                <Input
                  id={`${formId}CreatedAt`}
                  name="createdAt"
                  type="text"
                  value={account.createdAt}
                  disabled={true}
                />
              </Col>
            </FormGroup>
          )}
        </div>
        <div className="col-sm-6">
          {account.updatedAt && (
            <FormGroup row>
              <Label
                for={`${formId}UpdatedAt`}
              >
                Updated
              </Label>
              <Col>
                <Input
                  id={`${formId}UpdatedAt`}
                  name="updatedAt"
                  type="text"
                  value={account.updatedAt}
                  disabled={true}
                />
              </Col>
            </FormGroup>
          )}
        </div>
      </div>
    </Form>
  )
}

export default CreateAccountForm
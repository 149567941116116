import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const Navdata = () => {
  const history = useNavigate();

  const [isAccounts, setIsAccounts] = useState(true)
  const [isUploadCalls, setIsUploadCalls] = useState(false)
  const [isDailyWrapUps, setIsDailyWrapUps] = useState(false)
  const [isCurrentState, setIsCurrentState] = useState('Dashboard');

  const menuItems = [
    {
      label: "Dashboard",
      isHeader: true,
    },
    {
      id: "accounts",
      label: "Accounts",
      icon: "ri-store-fill",
      link: "/dashboard/accounts",
      stateVariables: isAccounts,
      click: function (e) {
        e.preventDefault();
        setIsAccounts(!isAccounts);
        setIsCurrentState('Accounts');
      }
    },
    {
      id: "daily-wrap-ups",
      label: "Daily Wrap-ups",
      icon: "ri-archive-drawer-fill",
      link: "/dashboard/daily-wrap-ups",
      stateVariables: isDailyWrapUps,
      click: function (e) {
        e.preventDefault();
        setIsDailyWrapUps(!isDailyWrapUps);
        setIsCurrentState('Daily Wrap-ups');
      }
    },
    {
      id: "upload-calls",
      label: "Upload Calls",
      icon: "ri-file-upload-line",
      link: "/dashboard/upload-calls",
      stateVariables: isUploadCalls,
      click: function (e) {
        e.preventDefault();
        setIsUploadCalls(!isAccounts);
        setIsCurrentState('Upload Calls');
      }
    },
  ];
  return (
    <React.Fragment>
      {menuItems}
    </React.Fragment>
  );
};
export default Navdata;
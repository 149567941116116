import React from "react";
import ReactApexChart from "react-apexcharts";
import { getChartColorsArray } from "../../../helpers/chartColorHelper";

const LeadSourcePieChart = ({ dataColors, leadSources }) => {
  var chartDonutBasicColors = getChartColorsArray(dataColors);
  const labels = leadSources.map(x => x.title)

  var options = {
      chart: {
          height: 500,
          type: 'pie',
      },
      labels,
      legend: {
          position: 'bottom'
      },
      dataLabels: {
          dropShadow: {
              enabled: false,
          }
      },
      colors: chartDonutBasicColors,
      tooltip: {
        shared: true,
        y: [{
          formatter: function (y) {
            if (typeof y !== "undefined") {
              return `${y}%`;
            }
            return y;
          }
        }]
      }
  };

  const totalLeadSources = leadSources.reduce((prev, curr) => prev + parseInt(curr.lead_count), 0)
  const series = leadSources.map(x => Math.round((parseInt(x.lead_count)/totalLeadSources)*1000) / 10 )

  return (
      <ReactApexChart
        className="apex-charts"
        series={series}
        options={options}
        type="pie"
        height={267.7}
      />
  )
}

export default LeadSourcePieChart
import React from "react";
import SimpleBar from "simplebar-react";
import moment from "moment";

export const RecentActivityScoller = ({
  events
}) => {
  if (!events) return <div></div>

  const getEventProperties = (event) => {
    let context = 'primary'
    let icon = 'ri-checkbox-blank-circle-fill'

    const momentDate = moment(event.createdAt)
    const dateRelative = momentDate.fromNow()
    const tooltipDate = momentDate.format("YYYY-MM-DD hh:mm:ss a")

    if (event.eventType === "job") {
      icon = 'ri-briefcase-fill'

      if (event.eventTitle === 'Job Scheduled') {
        icon = 'ri-calendar-fill'
        context = 'success'
      }
      else if (event.eventTitle === 'Pro Canceled' || event.eventTitle === 'User Canceled') {
        icon = 'ri-close-fill'
        context = 'warning'
      }
    } else if (event.eventType === "call") {
      icon = 'ri-phone-fill'

      if (event.eventTitle === 'Issue and Escalated' || event.eventTitle === 'Issue and Resolved') {
        icon = 'ri-error-warning-fill'
        context = 'danger'
      }
      else if (event.eventTitle === 'Booked Order/Estimate' || event.eventTitle === 'Other - Booked Order/Estimate') {
        icon = 'bx bx-dollar'
        context = 'success'
      } else if (event.eventTitle === 'Attempting Contact - LVM') {
        icon = 'bx bx-phone-outgoing'
      }
    }
    
    return {
      context,
      icon,
      dateRelative,
      tooltipDate
    }
  }

  return (
    <SimpleBar style={{ maxHeight: "410px" }} className="pt-0">
      <div className="acitivity-timeline acitivity-main">
        {events.map(event => {
          const eventProperties = getEventProperties(event)
          
          return (
            <div key={event.id} className="acitivity-item py-1 d-flex">
              <div className="flex-shrink-0 avatar-xs acitivity-avatar">
                <div className={`avatar-title bg-soft-${eventProperties.context} text-${eventProperties.context} rounded-circle`}>
                  <i className={eventProperties.icon}></i>
                </div>
              </div>
              <div className="flex-grow-1 ms-3">
                <h6 className="mb-1 lh-base">{event.eventTitle}</h6>
                <p className="text-muted mb-1">
                  {event.description}
                </p>
                <small className="mb-0 text-muted" title={eventProperties.tooltipDate}>{eventProperties.dateRelative}</small>
              </div>
            </div>
          )
        })}
      </div>
    </SimpleBar>
  )
}
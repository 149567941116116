import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

function CreateOrUpdateModal({
  cancelText = 'Cancel',
  form,
  open,
  setOpen,
  submitButton,
  title
}) {
  const toggle = () => setOpen(!open);

  return (
    <span>
      <Modal isOpen={open} toggle={toggle}>
        <ModalHeader toggle={toggle}>{title}</ModalHeader>
        <ModalBody>
          {form}
        </ModalBody>
        <ModalFooter>
          {submitButton}
          {' '}
          <Button color="light" onClick={() => setOpen(false)}>
            {cancelText}
          </Button>
        </ModalFooter>
      </Modal>
    </span>
  );
}

export default CreateOrUpdateModal
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react'

const auth0Domain = process.env.REACT_APP_AUTH0_DOMAIN
const f2gDomain = process.env.REACT_APP_F2G_API_DOMAIN

function useAccount(id, summary) {
  const navigate = useNavigate()
  const { user, getAccessTokenSilently } = useAuth0()
  const [loading, setLoading] = useState(true)
  const [accountData, setAccountData] = useState([])

  useEffect(() => {
    const getAccountData = async () => {
      setLoading(true)

      try {
        const accessToken = await getAccessTokenSilently({
          audience: `https://${auth0Domain}/api/v2/`,
          scope: "read:current_user",
        });

        const accountUrl = `${f2gDomain}/accounts/${id}?summary=${summary || false}`;

        const accountsDataResponse = await fetch(accountUrl, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (accountsDataResponse.status === 404) {
          return navigate("/dashboard/accounts")
        }

        const { data } = await accountsDataResponse.json()

        setAccountData(data)
        setLoading(false)
      } catch (e) {
        console.log(e.message);
      }
    };

    getAccountData();
  }, [getAccessTokenSilently, user?.sub, id, navigate, summary]);

  return { data: accountData, loading };
}

export default useAccount
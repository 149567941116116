import React, { useState } from "react"
import { Col, Container, Row, Button, Input } from "reactstrap"
import { useAuth0 } from "@auth0/auth0-react"

import BreadCrumb from "../../Components/Common/BreadCrumb"
import Loader from "../../Components/Common/Loader"

const domain = process.env.REACT_APP_AUTH0_DOMAIN
const apiDomain = process.env.REACT_APP_F2G_API_DOMAIN

const UploadCalls = () => {
  const { getAccessTokenSilently } = useAuth0()

  const [loading, setLoading] = useState(false)
  const [file, setFile] = useState(null)

  document.title = "Upload Calls"

  const onFileChange = (e) => {
    setFile(e.target.files[0])
  }

  const onFileUpload = async () => {
    setLoading(true)
    const accessToken = await getAccessTokenSilently({
      audience: `https://${domain}/api/v2/`,
      scope: "read:current_user",
    });

    const formData = new FormData();

    formData.append(
      "uploadFile",
      file,
      file.name
    );

    await fetch(`${apiDomain}/upload-calls`, {
      method: 'POST',
      headers: {
        "Authorization": `Bearer ${accessToken}`,
      },
      body: formData
    });

    setFile(null)
    setLoading(false)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb parentTitle="Dashboard" parentLink="/dashboard" childLabel="Upload Calls" title="Upload Calls" />
          <Row>
            <Col>
              <div className="h-100">
                <Row>
                  <Col>
                    {loading && (
                      <Loader />
                    )}

                    {!loading && (
                      <>
                        <p>Use the input below to upload calls from your TalkDesk export file.</p>
                        <Input
                          id="uploadFile"
                          name="file"
                          type="file"
                          onChange={onFileChange}
                        />
                        <br />
                        <Button color="primary" onClick={() => onFileUpload()}>
                          Upload
                        </Button>
                      </>
                    )}
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default UploadCalls;

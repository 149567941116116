import React from "react"
import { Button } from "reactstrap"

const SubmitFormButton = ({ loading, formId }) => {
  return (
    <Button color="primary" disabled={loading} form={formId} type="submit">
      {loading ? 'Saving...' : 'Save'}
    </Button>
  )
}

export default SubmitFormButton
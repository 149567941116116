import { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react'

const domain = process.env.REACT_APP_AUTH0_DOMAIN

function useUserMetadata() {
    const { user, getAccessTokenSilently } = useAuth0()
  const [metadata, setMetadata] = useState({});

  useEffect(() => {
    const getUserMetadata = async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          audience: `https://${domain}/api/v2/`,
          scope: "read:current_user",
        });
  
        const userDetailsByIdUrl = `https://${domain}/api/v2/users/${user.sub}`;
  
        const metadataResponse = await fetch(userDetailsByIdUrl, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
  
        const { app_metadata } = await metadataResponse.json()
  
        setMetadata(app_metadata)
      } catch (e) {
        console.log(e.message);
      }
    };
  
    getUserMetadata();
  }, [getAccessTokenSilently, user?.sub]);

  let role = "User"
    if (metadata.isDeveloper) {
        role = "Developer"
    } else if (metadata.isAdmin) {
        role = "Administrator"
    } else if (metadata.isInternalUser) {
        role = "Internal User"
    } else {
        role = "External User"
    }

  return { ...metadata, role };
}

export default useUserMetadata
import React, { useState } from 'react'
import { useAuth0 } from "@auth0/auth0-react";

const domain = process.env.REACT_APP_AUTH0_DOMAIN

const DeleteListItemButton = ({
  children,
  noun,
  endpoint
}) => {
  const { getAccessTokenSilently } = useAuth0()
  const [deleteLoading, setDeleteLoading] = useState(false)

  const deleteAccount = async () => {
    if (window.confirm(`Are you sure you want to delete this ${noun || 'item'}?`)) {
      setDeleteLoading(true)

      try {
        const accessToken = await getAccessTokenSilently({
          audience: `https://${domain}/api/v2/`,
          scope: "read:current_user",
        });

        await fetch(endpoint, {
          method: 'DELETE',
          headers: {
            "Authorization": `Bearer ${accessToken}`,
            "Content-Type": 'application/json',
          },
        });

        setDeleteLoading(false)
        window.location.reload()
      } catch (e) {
        console.log(e)
      }
    }
  }

  return (
    <button onClick={() => deleteAccount()} type="button" className="btn btn-light" disabled={deleteLoading}>
      {children}
    </button>
  )
}

export default DeleteListItemButton
import React, { useState } from "react";
import {
  Form,
  FormGroup,
  Label,
  Col,
  Input,
  FormFeedback
} from "reactstrap";

const CreateContactForm = ({
  contact: contactTemplate,
  loading,
  errors,
  onSubmit,
  formId,
}) => {
  const [contact, setContact] = useState(!!contactTemplate ? contactTemplate : {
    id: null,
    accountId: null,
    firstName: '',
    lastName: '',
    email: '',
    phone: ''
  })

  const errorMap = errors.reduce((prev, curr) => {
    prev[curr.param] = curr.msg
    return prev
  }, {})

  return (
    <Form id={formId} onSubmit={(e) => onSubmit(e, contact)}>
      <FormGroup row>
        <Label
          for={`${formId}FirstName`}
          sm={3}
        >
          First Name
        </Label>
        <Col sm={9}>
          <Input
            id={`${formId}Name`}
            name="firstName"
            placeholder="First name"
            type="text"
            value={contact.firstName}
            disabled={loading}
            onChange={(e) => setContact({...contact, firstName: e.target.value })}
            invalid={!!errorMap.firstName}
          />
          <FormFeedback>{errorMap.firstName}</FormFeedback>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label
          for={`${formId}LastName`}
          sm={3}
        >
          Last Name
        </Label>
        <Col sm={9}>
          <Input
            id={`${formId}LastName`}
            name="lastName"
            placeholder="Last name"
            type="text"
            value={contact.lastName}
            disabled={loading}
            onChange={(e) => setContact({...contact, lastName: e.target.value })}
            invalid={!!errorMap.lastName}
          />
          <FormFeedback>{errorMap.lastName}</FormFeedback>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label
          for={`${formId}Email`}
          sm={3}
        >
          Email
        </Label>
        <Col sm={9}>
          <Input
            id={`${formId}Email`}
            name="email"
            placeholder="Email Adress"
            type="text"
            value={contact.email}
            disabled={loading}
            onChange={(e) => setContact({...contact, email: e.target.value })}
            invalid={!!errorMap.email}
          />
          <FormFeedback>{errorMap.email}</FormFeedback>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label
          for={`${formId}Phone`}
          sm={3}
        >
          Phone Number
        </Label>
        <Col sm={9}>
          <Input
            id={`${formId}Phone`}
            name="phone"
            placeholder="Phone Number"
            type="text"
            value={contact.phone}
            disabled={loading}
            onChange={(e) => setContact({...contact, phone: e.target.value })}
            invalid={!!errorMap.phone}
          />
          <FormFeedback>{errorMap.phone}</FormFeedback>
        </Col>
      </FormGroup>
    </Form>
  )
}

export default CreateContactForm